<template>
  <div class="_wanxin">
    <!-- 横幅 -->
    <div class="hf" @click="godetail(zuixin.sharesalt)">
      <img src="../../../assets/img/new/newf.png" class="icon" alt="" />
      <div class="fongtone">最新动态:</div>
      <div class="shengyu">
        {{ zuixin == null ? '' : zuixin.title }}
      </div>
    </div>
    <!-- top切换 -->
    <div class="top">
      <div class="zuo">
        <div class="btn" :class="{ actfont: num == 1 }" @click="wan(1)">
          网信动态
        </div>
        <div class="btn" :class="{ actfont: num == 2 }" @click="wan(2)">
          网安知识
        </div>
      </div>
      <div class="you" @click="wananxin">
        <div class="fonttwo">更多</div>
        <img class="jian" src="../../../assets/img/new/zuojians.png" alt="" />
      </div>
    </div>
    <!-- 线切换 -->
    <div class="xian">
      <div class="kuai" v-if="yannum != 1"></div>
      <div
        class="kuai"
        :class="{ actyi: num == 2, acthui: num == 1, opacity: yannum == 0 }"
      ></div>
    </div>
    <!-- 内容 -->
    <div class="cneter">
      <!-- 模板 -->
      <div
        class="hang"
        v-for="(item, index) in onelist"
        :key="index"
        @click="godetail(item.sharesalt)"
      >
        <div class="flex align-items">
          <div class="qiu"></div>
          <div class="fonthree">
            {{ item.title }}
          </div>
        </div>
        <div class="fontfour">{{ item.createtime.slice(0, 10) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/infos.js";
export default {
  inject: ["hasScrollbar"],
  data() {
    return {
      num: 1,
      yannum: 0, //遮蔽动画
      onelist: [],
      zuixin:null,//最新的一条
    };
  },
  mounted() {
    this.fenlist(1);
    setTimeout(() => {
      this.yannum = 1;
    }, 1000);
  },
  watch: {},
  methods: {
    wananxin() {
      this.jumpDel()
      if(this.num == 1){
        this.$store.state.loginMessage.price = 4
        this.jump("infoslistt", this.$store.state.loginMessage);
      }else{
        this.$store.state.loginMessage.price = 5
        this.jump("infoslists", this.$store.state.loginMessage);
      }
      
    },
    //跳转到详情页
    godetail(id) {
      this.$store.state.loginMessage.wid = id;
      window.localStorage.setItem("xin", 1);
      const routeData = this.$router.resolve({
        path: "/infosdetail",
        query: {
          'wid':id
        }
      });
      // this.$store.state.loginMessage,
      window.open(routeData.href, "_blank");
    },
    wan(num) {
      this.num = num;
      this.fenlist(num);
    },
    // 列表详情
    async fenlist(num) {
      let res = await API.share({
        search: "",
        page: 1,
        pageLine: 10,
        type: num,
      }).then((res) => {
        if (res.code == 1) {
          this.onelist = res.data.list.splice(0, 6);
          if(this.zuixin == null){
            this.zuixin = this.onelist[0]
            // console.log(this.zuixin.sharesalt,'手机号');
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
._wanxin {
  width: 100%;
}
.hf {
  width: 100%;
  height: 58px;
  background: #f1f7ff;
  display: flex;
  align-items: center;
  margin-bottom: 19px;
  padding: 0 24px 0 16px;
  box-sizing: border-box;
  cursor: pointer;
}
.icon {
  width: 38px;
  height: 38px;
  margin-right: 5px;
}
.fongtone {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #e44a4f;
  font-weight: 400;
  margin-right: 5px;
}
.shengyu {
  flex: 1;
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #18191a;
  font-weight: 400;
  overflow: hidden; /* 隐藏超出部分的文本内容 */
  text-overflow: ellipsis; /* 使用省略号表示被隐藏的文本内容 */
  white-space: nowrap; /* 防止文本换行 */
}
.top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.zuo {
  display: flex;
}
.you {
  display: flex;
  align-items: center;
  cursor: pointer;
  .jian {
    width: 18px;
    height: 18px;
    margin-left: 1px;
  }
}
.btn {
  width: 121px;
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #0073ed;
  letter-spacing: 0;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
}
.xian {
  width: 100%;
  height: 2px;
  position: relative;
  border-top: 1px solid rgba(99, 132, 169, 0.21);
  margin-top: 12px;
  position: relative;
}
.kuai {
  width: 121px;
  height: 1.5px;
  border: 1px solid #024798;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
}
.fonttwo {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #484c51;
  font-weight: 400;
}
.actfont {
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #034899;
  letter-spacing: 0;
  font-weight: 600;
}
.actyi {
  animation: yidong 1s infinite forwards;
  animation-iteration-count: 1;
}
@keyframes yidong {
  0% {
    left: 0;
  }
  100% {
    left: 121px;
  }
}
.acthui {
  animation: hui 1s infinite forwards;
  animation-iteration-count: 1;
}
@keyframes hui {
  0% {
    left: 121px;
  }
  100% {
    left: 0px;
  }
}
.cneter {
  // width: 100%;
  margin-top: 9.6px;
  padding: 0 0 0 16px;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.hang {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14.4px;
  cursor: pointer;
  .qiu {
    width: 6px;
    height: 6px;
    overflow: hidden;
    border-radius: 50%;
    background: #fd880c;
    margin-right: 12px;
  }
  .fonthree {
    width: 339px;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #313235;
    font-weight: 400;
    overflow: hidden; /* 隐藏超出部分的文本内容 */
    text-overflow: ellipsis; /* 使用省略号表示被隐藏的文本内容 */
    white-space: nowrap; /* 防止文本换行 */
  }
  .fontfour {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #484c51;
    letter-spacing: 0;
    text-align: right;
    font-weight: 400;
  }
}
</style>